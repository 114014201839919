import React from "react";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";
import SectionBottomCta from "../components/SectionBottomCta.js";

import { Wrapper, SectionH2, SectionParag, Section1ColWhite } from "../styles/style.js";

const BigButton = styled(Link)`
  display: block;
  background-color: orange;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  padding: 15px 20px;
  border-radius: 3px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
`;

const SmallButton = styled(BigButton)`
  padding: 10px 15px;
  max-width: 200px;
  background-color: gray;
`;

const FleetLimoPage = () => (
  <Layout>
    <Seo
      title="Lincoln Limousine"
    />

    <Section1ColWhite>
      <Wrapper>
        <StaticImage src="../img/fleet-lincoln-limo.png" alt="Lincoln Limousine" />
        <SectionH2>Lincoln Limousine</SectionH2>
        <SectionParag>
          Our beautiful new body style stretch limousines are fully loaded with AC, Bar, Sunroof, Fiber Optic Lighting, DVD, CD, USB, Champagne Bucket, Cocktail Glasses, Sodas, Ice and Bottled Water. Alcohol available upon request.
        </SectionParag>
        <SectionParag>Capacity: 4 to 9 Passengers</SectionParag>
        <BigButton to="/quote">Request Free Quote</BigButton>
        <SmallButton to="/fleet">View Fleet</SmallButton>
      </Wrapper>
    </Section1ColWhite>

    <SectionBottomCta grayBackground />
  </Layout>
);

export default FleetLimoPage